import 'normalize.css';
import '../Style/Index.css';
import '@fortawesome/fontawesome-free/js/all';
import Vue from 'vue';
import axios from 'axios';
import url from 'domurl';
import { BaseUrl, SchoolType } from './BaseUrl';
import { alertBox } from './Alert';
window.onload = function () {
  let UrlInfo = new url();
  let callback = UrlInfo.query.callback;
  let code = UrlInfo.query.code;
  let state = UrlInfo.query.state;
  if (callback != undefined && code != undefined && state != undefined) {
    axios
      .get(
        `${BaseUrl}/api/User/Tyc_Login/${code}/${state}/${localStorage.getItem(
          'System_Type_ID'
        )}/${localStorage.getItem('Subject_ID')}`
      )
      .then(function (res) {
        alertBox({
          Html: res.data.Success
            ? `<span class="text-green-700">${res.data.Data.UserData.Name}您好，歡迎使用桃園市政府教育局OpenID登入本系統。<span>`
            : `<span class="text-red-700">${res.data.Message}<span>`,
          OnOK: function () {
            location.href = `Index.html?System_Type_ID=${localStorage.getItem(
              'System_Type_ID'
            )}`;
          },
        });
      })
      .catch(function (res) {});

    return false;
  }

  let System_Type_ID = UrlInfo.query.System_Type_ID
    ? UrlInfo.query.System_Type_ID
    : 0;

  let ValidStatus = UrlInfo.query.ValidStatus;
  let ForGetStatus = UrlInfo.query.ForGetStatus;
  let Code = UrlInfo.query.Code;
  System_Type_ID = 1;

  let vue_object = new Vue({
    el: '#app',
    data: {
      NewsAddWindow: false,
      SchoolType: SchoolType,
      Config: {
        MenuList: [],
        NewsList: [],
        SubjectList: [],
        SubjectInfo: {
          Name: '',
        },
        Title: '學生資賦優異鑑定報名系統',
        NewsCount: 0,
        CanLogin: false,
        UseCaptcha: false,
        UserData: {
          Account: '',
          ID: 0,
          Identity_ID: 0,
          Mail: '',
          Password: '',
          Subject_ID: 0,
          System_Type_ID: System_Type_ID,
          Valid: false,
        },
      },
      userdata: {
        Account: '',
        Mail: '',
        Name: '',
        Old_Password: '',
        New_password: '',
        Recheck: '',
      },
      BaseUrl: BaseUrl,
      IsShow_SideBar: false,
      IsShow_News: false,
      IsShow_Login: false,
      IsShow_Register: false,
      IsShow_Forgot: false,
      IsShow_Reset: false,
      NewsTitle: '',
      NewsUnit: '',
      NewsContent: '',
      NewsDate: '',
      NewsFile: [],
      MaxPage: 1,
      NowPage: 1,
      Keyword: '',
      Subject_ID: 0,
      Account: '',
      Password: '',
      RePassword: '',
      Mail: '',
      Captch: '',
      IsForeigner: false,
      Captcha_url: BaseUrl + '/Images/Captcha.ashx?_=' + new Date().getTime(),
    },
    methods: {
      Init: function () {
        let my = this;
        axios
          .get(BaseUrl + `/api/Config/GetAll/${System_Type_ID}/1`)
          .then(function (res) {
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }
            my.Config = res.data.Data;

            //計算公告頁數
            my.MaxPage =
              my.Config.NewsCount == 0
                ? 1
                : Math.ceil(my.Config.NewsCount / 10);

            if (
              my.Config.UserData.System_Type_ID != System_Type_ID &&
              my.Config.UserData.Identity_ID != 0
            ) {
              my.ChangeSubject(1);
            }
            if (
              callback != undefined &&
              callback == 'Login' &&
              my.Config.CanLogin == true
            ) {
              alertBox({
                Html: `<span class="text-red-700">請先登入後再試。</span>`,
                OnOK: function () {
                  my.LoginBtn();
                },
              });
            }
            if (
              callback != undefined &&
              callback == 'Login' &&
              my.Config.CanLogin == false
            ) {
              my.LoginBtn();
            }
            sessionStorage.setItem('Identity', my.Config.UserData.Identity_ID);

            document.title = my.Config.Title;
          })
          .catch(function (err) {
            alertBox({
              Mode: 'C',
              Html: `<span class="text-[rgb(255,0,0)]">獲取系統設定異常：${err}</span>`,
            });
          });
      },
      // 切換科目
      ChangeSubject: function (Type = 0) {
        let GetUrl = `${BaseUrl}/api/Config/GetSubjectList/${System_Type_ID}/`;
        let my = this;
        axios
          .get(GetUrl)
          .then(function (res) {
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }

            if (res.data.Data.SubjectList.length == 0) {
              throw new Error('查無科目資料');
            }

            let tmp_html = '';

            if (
              res.data.Data.SubjectList != null &&
              res.data.Data.SubjectList.length == 1
            ) {
              GetUrl = `${BaseUrl}/api/Config/ChangeSubject/${res.data.Data.SubjectList[0].ID}/${System_Type_ID}`;
              let SubjectName = res.data.Data.SubjectList[0].Name;
              axios
                .get(GetUrl)
                .then(function (res) {
                  if (!res.data.Success) {
                    throw new Error(res.data.Message);
                  }
                  localStorage.clear();
                  alertBox({
                    Mode: 'A',
                    Html: `<span class="text-green-700">科目切換完成(${SubjectName})！！</span>`,
                    OnOK: function () {
                      location.reload();
                    },
                  });
                })
                .catch(function (err) {
                  alertBox({
                    Html: err,
                  });
                });
            } else {
              for (let i = 0; i < res.data.Data.SubjectList.length; i++) {
                let check =
                  res.data.Data.SubjectList[i].ID ==
                  my.Config.UserData.Subject_ID
                    ? true
                    : false;
                tmp_html += `<input class="ml-1" id="Change_Subject_${
                  res.data.Data.SubjectList[i].ID
                }" type="radio" ${
                  check ? ` checked="checked" ` : ''
                } name="ChangeSubject" value="${
                  res.data.Data.SubjectList[i].ID
                }"/><label for="Change_Subject_${
                  res.data.Data.SubjectList[i].ID
                }">${res.data.Data.SubjectList[i].Name}</label>`;
              }

              alertBox({
                Mode: 'C',
                Title: `切換科目`,
                Html: `
                <table class="">
                  <tr>
                    <td class="font-bold">${
                      Type == 0
                        ? '請選擇科目'
                        : '系統判斷您選擇了不同的入口，請重新選擇報名科目'
                    }</td>
                  </tr>
                  <tr>
                  
                  <td >
                  ${tmp_html}
                </td>
                  </tr>
                  <tr>
                    <td class="font-bold" colspan="2"><span class="text-red-600">僅顯示 「可報名 or 已報名」的科目！！</span></td>
                  </tr>
                </table>`,
                OnOK: function () {
                  let Select = document.querySelector(
                    'input[name="ChangeSubject"]:checked'
                  );
                  if (Select == null) {
                    alertBox({
                      Html: `「科目」尚未選擇！！`,
                    });
                    return false;
                  }

                  if (Select.value == my.Config.UserData.Subject_ID) {
                    alertBox({
                      Mode: 'A',
                      Html: `<span class=" text-red-700">您已在此科目，無須切換！！</span>`,
                    });
                    return false;
                  }

                  GetUrl = `${BaseUrl}/api/Config/ChangeSubject/${Select.value}/${System_Type_ID}`;
                  axios
                    .get(GetUrl)
                    .then(function (res) {
                      if (!res.data.Success) {
                        throw new Error(res.data.Message);
                      }
                      localStorage.clear();
                      alertBox({
                        Mode: 'A',
                        Html: `<span class="text-green-700">科目切換完成！！</span>`,
                        OnOK: function () {
                          location.reload();
                        },
                      });
                    })
                    .catch(function (err) {
                      throw new Error(err);
                    });
                },
                Onclose: function () {
                  location.href = 'Index.html';
                },
              });
            }
          })
          .catch(function (err) {
            alertBox({
              Mode: `A`,
              Html: `<span class="text-red-500">${err}</span>`,
            });
          });
      },
      OpenIDBtn: function () {
        let my = this;
        if (!my.Config.CanLogin) {
          alertBox({
            Html: `<span class="text-red-700">系統尚未開放登入，請詳閱簡章報名時間。</span>`,
          });
          return false;
        }
        if (my.Subject_ID == 0) {
          alertBox({
            Html: `<span class="text-red-700">請先選擇報名科目</span>`,
          });
          return false;
        }

        localStorage.setItem('System_Type_ID', System_Type_ID);
        localStorage.setItem('Subject_ID', my.Subject_ID);
        location.href = `https://tyc.sso.edu.tw/auth-server-stlogin?Auth_Request_RedirectUri=${
          my.SchoolType == 1
            ? 'https%253A%252F%252Fwww.giftedness.tyc.edu.tw%252FCreativeAbility%253Fcallback%253Dopenid'
            : 'https%253A%252F%252Fwww.giftedness.tyc.edu.tw%252FAcademicallyGifted%253Fcallback%253Dopenid'
        }&Auth_Request_State=201558592v1&Auth_Request_Response_Type=code&Auth_Request_Client_ID=ca201558592fdde984b2cb31238b15ac&Auth_Request_Nonce=2&Auth_Request_Scope=openid+email+profile&local=true`;
      },
      // 側邊選單開關
      sidebarBtn: function () {
        this.IsShow_SideBar = !this.IsShow_SideBar;
      },
      // 公告顯示按鈕
      showNews: function (index) {
        let News = this.Config.NewsList[index];
        this.NewsTitle = News.Title;
        this.NewsUnit = News.Unit_Name;
        this.NewsContent = News.Contents;
        this.NewsDate =
          News.Start_Date == ''
            ? News.Update_Date.split(' ')[0]
            : News.Start_Date;
        this.NewsFile = News.NewsFiles;
        this.IsShow_News = true;
      },
      // 公告關閉按鈕
      NewsCloseBtn: function () {
        this.IsShow_News = false;
      },
      //換頁
      NextPage: function () {
        let my = this;
        my.NowPage += 1;
        if (my.NowPage >= my.MaxPage) my.NowPage = my.MaxPage;
        my.GetNews(false);
      },
      //換頁
      LastPage: function () {
        let my = this;
        my.NowPage -= 1;
        if (my.NowPage <= 1) my.NowPage = 1;
        my.GetNews(false);
      },
      //關鍵字搜尋
      GetNews: function (Search) {
        let my = this;
        let GetUrl = `${BaseUrl}/api/Config/GetNewsList/${System_Type_ID}/${
          Search ? 0 : (my.NowPage - 1) * 10
        }/${10}/${my.Keyword}/`;
        axios
          .get(GetUrl)
          .then(function (res) {
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }

            my.Config.NewsCount = res.data.Data.NewsCount;
            my.Config.NewsList = res.data.Data.NewsList;
            my.MaxPage =
              my.Config.NewsCount == 0
                ? 1
                : Math.ceil(my.Config.NewsCount / 10);
            my.NowPage = Search ? 1 : my.NowPage;
          })
          .catch(function (err) {
            alertBox({
              Mode: `A`,
              Html: `<span class="text-red-500">${err}</span>`,
            });
          });
      },
      // 登入顯示按鈕
      LoginBtn: function () {
        if (!this.Config.CanLogin) {
          alertBox({
            Html: `<span class="text-red-700">系統尚未開放登入，請詳閱簡章報名時間。</span>`,
          });
          return false;
        }
        this.CloseWindow();
        if (this.Config.SubjectList.length == 1) {
          this.Subject_ID = this.Config.SubjectList[0].ID;
        }
        this.IsShow_Login = true;
      },
      // 註冊顯示按鈕
      RegisterBtn: function () {
        this.CloseWindow();
        this.IsShow_Register = true;
      },
      // 忘記密碼顯示按鈕
      ForgotBtn: function () {
        this.CloseWindow();
        this.IsShow_Forgot = true;
      },
      // 視窗關閉 (登入、註冊、忘記密碼)
      CloseWindow: function () {
        this.ChangeCaptcha();
        this.Account = '';
        this.Password = '';
        this.RePassword = '';
        this.Mail = '';
        this.Captch = '';
        this.IsForeigner = false;
        this.Subject_ID = 0;
        this.IsShow_Login = false;
        this.IsShow_Register = false;
        this.IsShow_Forgot = false;
        this.IsShow_Reset = false;
      },
      //重設密碼
      Reset: function () {
        let my = this;
        //判斷是否都填寫正確

        if (this.Mail == '') {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「電子信箱」尚未輸入</span>`,
          });
          return false;
        }

        if (this.Password == '') {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「密碼」尚未輸入</span>`,
          });
          return false;
        }

        if (this.RePassword == '') {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「確認密碼」尚未輸入</span>`,
          });
          return false;
        }

        if (this.RePassword != this.Password) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「密碼」與「確認密碼」不相符！！</span>`,
          });
          return false;
        }

        if (this.Config.UseCaptcha && this.Captch.length != 4) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「驗證碼」輸入錯誤!!</span>`,
          });
          return false;
        }

        let PostData = {
          Mail: this.Mail,
          Password: this.Password,
          RePassword: this.RePassword,
          Captcha: this.Captch,
          Code: Code,
        };

        let PostUrl = `${BaseUrl}/api/User/ResetPassword/`;
        axios
          .post(PostUrl, PostData)
          .then(function (res) {
            let Data = res.data;
            if (!Data.Success) {
              throw new Error(Data.Message);
            }
            alertBox({
              Mode: 'A',
              Html: `<span style="color:green">${Data.Message}</span>`,
              OnOK: function () {
                my.CloseWindow();
                my.Init();
              },
            });
          })
          .catch(function (err) {
            alertBox({
              Mode: 'A',
              Html: `<span style="color:red">${err}</span>`,
            });
          });
      },
      // 註冊功能
      Register: function () {
        let my = this;
        //判斷是否都有輸入或選擇
        if (this.IsForeigner && this.Account.length < 6) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">非本國籍人士，帳號長度必須大於6碼</span>`,
          });
          return false;
        }

        if (!this.IsForeigner && this.Account.length != 10) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">帳號(身份證字號)輸入錯誤！！若為居留證號，請務必勾選"非本國籍人士"</span>`,
          });
          return false;
        }

        if (this.Mail.length == 0) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「電子信箱」尚未輸入!!</span>`,
          });
          return false;
        }

        if (this.Password.length < 6) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「密碼」長度必須大於６!!</span>`,
          });
          return false;
        }

        if (this.RePassword != this.Password) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「確認密碼」與「密碼」不相符！！</span>`,
          });
          return false;
        }

        if (this.Config.UseCaptcha && this.Captch.length != 4) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「驗證碼」輸入錯誤！！</span>`,
          });
          return false;
        }

        let PostData = {
          Account: this.Account,
          IsForeigner: this.IsForeigner,
          Mail: this.Mail,
          Password: this.Password,
          RePassword: this.RePassword,
          Captcha: this.Captch,
        };

        let PostUrl = `${BaseUrl}/api/User/Register/${System_Type_ID}/`;
        axios
          .post(PostUrl, PostData)
          .then(function (res) {
            let Data = res.data;
            if (!Data.Success) {
              throw new Error(Data.Message);
            }
            alertBox({
              Mode: 'A',
              Html: `<span style="color:green">${Data.Message}</span>`,
              OnOK: function () {
                my.CloseWindow();
              },
            });
          })
          .catch(function (err) {
            alertBox({
              Mode: 'A',
              Html: `<span style="color:red">${err}</span>`,
            });
          });
      },
      //登入功能
      Login: function () {
        let my = this;
        if (!my.Config.CanLogin) {
          alertBox({
            Html: `<span class="text-red-700">系統尚未開放登入，請詳閱簡章報名時間。</span>`,
          });
          return false;
        }
        //判斷是否都填寫正確
        if (this.Subject_ID == 0) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「報名科目」尚未選擇!!</span>`,
          });
          return false;
        }

        if (this.Account == '') {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「帳號」尚未輸入</span>`,
          });
          return false;
        }

        if (this.Password == '') {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「密碼」尚未輸入</span>`,
          });
          return false;
        }

        if (this.Config.UseCaptcha && this.Captch.length != 4) {
          alertBox({
            Mode: 'A',
            Html: `<span style="color:red">「驗證碼」輸入錯誤!!</span>`,
          });
          return false;
        }

        let PostData = {
          Account: this.Account,
          Password: this.Password,
          Captcha: this.Captch,
          Subject_ID: this.Subject_ID,
        };

        let PostUrl = `${BaseUrl}/api/User/Login/${System_Type_ID}/`;
        axios
          .post(PostUrl, PostData)
          .then(function (res) {
            let Data = res.data;
            if (!Data.Success) {
              throw new Error(Data.Message);
            }
            alertBox({
              Mode: 'A',
              Html: `<span style="color:green">${Data.Message}</span>`,
              OnOK: function () {
                location.href =
                  BaseUrl + `/Index.html?System_Type_ID=${System_Type_ID}`;
                localStorage.clear();
              },
            });
          })
          .catch(function (err) {
            alertBox({
              Mode: 'A',
              Html: `<span style="color:red">${err}</span>`,
            });
          });
      },
      // 忘記密碼功能
      Forget: function () {
        if (this.Mail.length == 0) {
          alertBox({
            Mode: `A`,
            Html: `<span class="text-red-500">「信箱」尚未輸入</span>`,
          });
          return false;
        }

        if (this.Config.UseCaptcha && this.Captch.length != 4) {
          alertBox({
            Mode: `A`,
            Html: `<span class="text-red-500">「驗證碼」輸入錯誤!!</span>`,
          });
          return false;
        }

        let PostUrl = `${BaseUrl}/api/User/ForgetPassword/${System_Type_ID}/${this.Mail}/${this.Captch}`;

        axios
          .post(PostUrl)
          .then(function (res) {
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }

            alertBox({
              Mode: `A`,
              Html: `<span class="text-green-500">密碼重設信件已發送至您的電子郵件信箱，請點選郵件內的重設連結即可設置新密碼。</span>`,
            });
          })
          .catch(function (err) {
            alertBox({
              Mode: `A`,
              Html: `<span class="text-red-500">${err}</span>`,
            });
          });
      },
      //變更驗證碼
      ChangeCaptcha: function (e) {
        this.Captcha_url =
          BaseUrl + '/Images/Captcha.ashx?_=' + new Date().getTime();
      },
      //登出
      LogOut: function () {
        let PostUrl = `${BaseUrl}/api/User/Logout/`;
        axios
          .get(PostUrl)
          .then(function (res) {
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }

            alertBox({
              Mode: `A`,
              Html: `<span class="text-green-500">登出成功</span>`,
              OnOK: function () {
                localStorage.clear();
                location.reload();
              },
            });
          })
          .catch(function (err) {
            alertBox({
              Mode: `A`,
              Html: `<span class="text-red-500">${err}</span>`,
            });
          });
      },
      CloseNewsAdd: function () {
        let my = this;
        my.NewsAddWindow = false;
      },
      Add_apply: function () {
        let my = this;
        let PostUrl = `${BaseUrl}/api/User/Setting/`;
        axios
          .post(PostUrl, my.userdata)
          .then(function (res) {
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }
            my.CloseNewsAdd();
            alertBox({
              Mode: `A`,
              Html: `<span class="text-green-500">修改成功，請使用新密碼重新登入!</span>`,
              OnOK: function () {
                let PostUrl = `${BaseUrl}/api/User/Logout/`;
                axios
                  .get(PostUrl)
                  .then(function (res) {
                    if (!res.data.Success) {
                      throw new Error(res.data.Message);
                    }
                    localStorage.clear();
                    location.reload();
                  })
                  .catch(function (err) {
                    alertBox({
                      Mode: `A`,
                      Html: `<span class="text-red-500">${err}</span>`,
                    });
                  });
              },
            });
          })
          .catch(function (err) {
            alertBox({
              Mode: `A`,
              Html: `<span class="text-red-500">${err}</span>`,
            });
          });
      },
      Cancel_apply: function () {
        let my = this;
        my.Init();
        my.CloseNewsAdd();
      },
      //編輯資料
      data_edit: function () {
        let my = this;
        axios
          .get(BaseUrl + `/api/Config/GetAll/${System_Type_ID}/1`)
          .then(function (res) {
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }
            let curData = res.data.Data.UserData;
            my.userdata.Account = curData.Account;
            my.userdata.Mail = curData.Mail;
            my.userdata.Name = curData.Name;
          })
          .catch(function (err) {
            alertBox({
              Mode: 'C',
              Html: `<span class="text-[rgb(255,0,0)]">獲取系統設定異常：${err}</span>`,
            });
          });
        my.NewsAddWindow = true;
      },
    },
    mounted() {
      // 監聽視窗變化
      window.onresize = () => {
        if (window.innerWidth >= 768) {
          this.IsShow_SideBar = false;
        }
      };
    },
  });
  vue_object.Init();
  //驗證帳號狀態提示
  if (ValidStatus != undefined) {
    window.history.pushState(
      {},
      0,
      `${UrlInfo.protocol}://${UrlInfo.host}${
        UrlInfo.port == 80 ? '' : `:${UrlInfo.port}`
      }/Index.html?System_Type_ID=${System_Type_ID}`
    );
    alertBox({
      Mode: `A`,
      Html:
        ValidStatus == 1
          ? `<span style="color:green">帳號驗證成功，登入後即可開始報名作業。</span>`
          : `<span style="color:red">帳號驗證失敗，請重新發送驗證信件後再試。</span>`,
      OnOK: function () {
        if (vue_object.Config.UserData.Identity_ID == 0) {
          vue_object.LoginBtn();
        }
      },
    });
  }

  // 忘記密碼狀態提示
  if (ForGetStatus != undefined) {
    window.history.pushState(
      {},
      0,
      `${UrlInfo.protocol}://${UrlInfo.host}${
        UrlInfo.port == 80 ? '' : `:${UrlInfo.port}`
      }/Index.html?System_Type_ID=${System_Type_ID}`
    );
    if (ForGetStatus == 0) {
      alertBox({
        Mode: `A`,
        Html: `<span style="color:red">忘記密碼連結失效，請重新申請後再試。</span>`,
        OnOK: function () {
          vue_object.ForgotBtn();
        },
      });
      return false;
    }

    vue_object.IsShow_Reset = true;
  }
};
